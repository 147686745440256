window.addEventListener('load', () => {
    document.querySelectorAll('.js-submodule-toggle').forEach((toggle) => {
        toggle.addEventListener('click', (e) => {
            e.preventDefault();
            const parent = toggle.closest('.group');
            let target;
            if(e.target.tagName != 'BUTTON') {
                target = e.target.closest('button');
            } else {
                target = e.target;
            }
            target.classList.add('hidden');
            if(target.classList.contains('js-close')) {
                parent.querySelector('.js-open').classList.remove('hidden');
                parent.querySelector('.js-sub-modules').classList.add('hidden');
            } else {
                parent.querySelector('.js-close').classList.remove('hidden');
                parent.querySelector('.js-sub-modules').classList.remove('hidden');
            }
        });
    });
});
