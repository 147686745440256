window.addEventListener('load', () => {
    const navigationContainer = document.querySelector('.js-navigaion');
    const toggleNaviation = document.querySelector('.js-toggle-navigation');
    if(toggleNaviation) {
        document.querySelector('.js-toggle-navigation').addEventListener('click', (e) => {
            e.stopPropagation();
            navigationContainer.classList.toggle('hidden');
        });
    }

    document.addEventListener('click', () => {
        if(navigationContainer) {
            let foundNavigationContainer = false;
            navigationContainer.classList.forEach((className) => {
                if (className == 'hidden') {
                    foundNavigationContainer = true;
                }
            });

            if (!foundNavigationContainer) {
                navigationContainer.classList.toggle('hidden');
            }
        }
    })
});
