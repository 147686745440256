
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
require('./components/submodule');
require('./components/header');
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";
import Vue from 'vue'

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
    flare.light();
}

Vue.use(flareVue);



/**
 * Register 3rd Party Vue Components
 */
window.swal = require('sweetalert2')
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import './vendors/filepond/filepond-plugin-image-edit.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from './vendors/filepond/filepond-plugin-image-edit.esm.min.js';

const FilePond = vueFilePond(
    FilePondPluginImageExifOrientation,
    // FilePondPluginImageTransform,
    // FilePondPluginImageResize,
    // FilePondPluginImagePreview,
    // FilePondPluginImageEdit
);
Vue.component('file-pond', FilePond);

import {Tabs, Tab} from 'vue-tabs-component';
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

import tippy from 'tippy.js';
window.tippy = tippy;
import 'tippy.js/dist/tippy.css'; // optional for styling

/**
 * My Components
 */
Vue.component('signature', () => import('./components/Signature.vue'));
Vue.component('file-upload', () => import('./components/FileUpload'));
Vue.component('datepicker', () => import('./components/Datepicker'));
Vue.component('data-table', () => import('./components/DataTable'));
Vue.component('modal', () => import('./components/Modal'));
Vue.component('create-order-modal', () => import('./components/CreateOrderModal'));
Vue.component('pdf-modal', () => import('./components/PdfModal'));
Vue.component('image-modal', () => import('./components/ImageModal'));
Vue.component('ifc-modal', () => import('./components/IfcModal/IfcModal'));
Vue.component('images-modal', () => import('./components/ImagesModal'));
require('./components/AreYouSure');
Vue.component('signature-editor', () => import('./components/SignatureEditor'));
Vue.component('routine-control-chart', () => import('./components/RoutineControlChart'));
Vue.component("language-selector", () => import("./components/LanguageSelector"));
Vue.component('control-plan-editor', () => import('./components/ControlPlan/editor'));
Vue.component('control-plan-table-editor', () => import('./components/ControlPlanTableEditor/ControlPlanTableEditor'));
Vue.component('control-report-table-editor', () => import('./components/ControlReportTableEditor/ControlReportTableEditor'));
Vue.component('control-plan-appendix', () => import('./components/ControlPlan/Appendixes'));
Vue.component('control-rapport', () => import('./components/ControlRapport/ControlRapport'));
Vue.component('self-control-tables', () => import('./components/SelfControlTables'));
Vue.component('self-control-rapport', () => import('./components/SelfControlRapport/SelfControlRapport'));
Vue.component('orders-select', () => import('./components/OrdersSelect'));
Vue.component('folders-template', () => import('./components/FileSystem/FoldersTemplate'));
Vue.component('welding-log-tables', () => import('./components/WeldingLog/WeldingLogTables'));


/**
 * My Views
 */
Vue.component('welding-certificates', () => import('./views/WeldingCertificates/index'));
Vue.component('welding-certificates-form', () => import('./views/WeldingCertificates/form'));
Vue.component('wps', () => import('./views/Wps'));
Vue.component('material-documentation', () => import('./views/MaterialDocumentation'));
Vue.component('material-certificate', () => import('./views/MaterialCertificate'));
Vue.component('delivery-note', () => import('./views/DeliveryNote'));
Vue.component('additive-material-certificate', () => import('./views/AdditiveMaterialCertificate'));
Vue.component('wpqr', () => import('./views/Wpqr'));
Vue.component('measuring-tools', () => import('./views/MeasuringTools'));
Vue.component('machines', () => import('./views/Machines/index'));
Vue.component('machines-form', () => import('./views/Machines/form'));
Vue.component('suppliers', () => import('./views/Suppliers/index'));
Vue.component('suppliers-form', () => import('./views/Suppliers/form'));
Vue.component('documents', () => import('./views/Documents/index'));
Vue.component('documents-form', () => import('./views/Documents/form'));
Vue.component('welders', () => import('./views/Welders'));
Vue.component('users', () => import('./views/Users'));
Vue.component('lengths', () => import('./views/Lengths/index'));
Vue.component('lengths-edit', () => import('./views/Lengths/edit'));
Vue.component('lengths-create', () => import('./views/Lengths/create'));
Vue.component('controls', () => import('./views/Controls/index'));
Vue.component('controls-create', () => import('./views/Controls/create'));
Vue.component('controls-edit', () => import('./views/Controls/edit'));
Vue.component('orders', () => import('./views/Orders.vue'));
Vue.component('user-create', () => import('./views/UserCreate'));
Vue.component('deviation-settings', () => import('./views/Deviations/Settings'));
Vue.component('deviations', () => import('./views/Deviations/index'));
Vue.component('deviations-edit', () => import('./views/Deviations/edit'));
Vue.component('corrective-actions', () => import('./views/CorrectiveActions/index'));
Vue.component('settings', () => import('./views/Settings'));
Vue.component('customers', () => import('./views/Customers'));
Vue.component('customer-input', () => import('./components/CustomerInput'));
Vue.component('wps-settings', () => import('./views/Settings/Wps'));
Vue.component('welding-certificate-settings', () => import('./views/Settings/WeldingCertificate'));
Vue.component('material-certificate-settings', () => import('./views/Settings/MaterialCertificate'));
Vue.component('material-documentation-settings', () => import('./views/Settings/MaterialDocumentation'));
Vue.component('additive-material-certificate-settings', () => import('./views/Settings/AdditiveMaterialCertificate'));
Vue.component('wpqr-settings', () => import('./views/Settings/Wpqr'));
Vue.component('documents-settings', () => import('./views/Settings/Documents'));
Vue.component('delivery-note-settings', () => import('./views/Settings/DeliveryNote'));
Vue.component('project-settings', () => import('./views/Settings/ProjectSettings'));
Vue.component('welding-coordination', () => import('./views/WeldingCoordination'));
Vue.component('control-plan-index', () => import('./views/ControlPlan/index'));
Vue.component('template-index', () => import('./views/ControlPlan/TemplateIndex'));
Vue.component('control-plan-edit', () => import('./views/ControlPlan/edit'));
Vue.component('control-rapport-index', () => import('./views/ControlRapport/index'));
Vue.component('control-rapport-edit', () => import('./views/ControlRapport/edit'));
Vue.component('self-control-index', () => import('./views/SelfControl/index'));
Vue.component('self-control-edit', () => import('./views/SelfControl/edit'));
Vue.component('self-control-rapport-index', () => import('./views/SelfControlRapport/index'));
Vue.component('self-control-rapport-edit', () => import('./views/SelfControlRapport/edit'));
Vue.component('a5-index', () => import('./views/A5/index'));
Vue.component('a5-edit', () => import('./views/A5/edit'));
Vue.component('a5-template', () => import('./views/A5/template'));
Vue.component('filesystem-form', () => import('./views/FileSystem/form'));
Vue.component('filesystem-index', () => import('./views/FileSystem/index'));
Vue.component('welding-log-index', () => import('./views/WeldingLog/index'));
Vue.component('welding-log-edit', () => import('./views/WeldingLog/edit'));
Vue.component('welding-log-registration', () => import('./views/WeldingLog/Registration'));
Vue.component('support', () => import('./views/Support'));
Vue.component('welding-log-settings', () => import('./views/Settings/WeldingLog'));
Vue.component('welding-log-template-index', () => import('./views/WeldingLogTemplate/index'));

/**
 * Filters
 */
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import axios from 'axios';
const daLocale = require('date-fns/locale/da')

Vue.filter('date', function (value) {
    if (!value) {
        return value;
    }

    return format(parse(value), 'D MMM YYYY', { locale: daLocale})
});

Vue.filter('i18n', function (value) {
    if (window.i18n[value]) {
        return window.i18n[value];
    }

    return value
});

Vue.filter('nl2br', function (value) {
    if (!value) {
        return value;
    }

    return value.replace(/\n/g, "<br />") ;
});

// listen for Ctrl + S keypress and cmd + S on mac
document.addEventListener('keydown', function(event) {
    if (
        event.ctrlKey && event.key === 's' ||
        event.metaKey && event.key === 's'
    ) {
        event.preventDefault();
        document.querySelector('.save-on-ctrl-s').submit();
    }
});


const app = new Vue({
    el: '#app',
    data: {
        showProfileMenu: false,
        showSettingsMenu: false,
        showNotifications: false,
        showMenu: false,
    },
    ignoreElements: ['#index-table'],
    methods: {
        hide_notification(id) {
            axios
                .post(`/api/feature-notification/${id}`)
                .then(() => {
                    document.querySelector('[data-feature-notification="'+ id +'"]').remove();
                })
        },

        toggleMenu(menu) {
            this.showProfileMenu = menu === 'showProfileMenu' ? !this.showProfileMenu : false;
            this.showSettingsMenu = menu === 'showSettingsMenu' ? !this.showSettingsMenu : false;
            this.showNotifications = menu === 'showNotifications' ? !this.showNotifications : false;
            if(menu === 'showNotifications') {
                axios
                    .post('/api/feature-notification')
                    .then(() => {
                        document.querySelectorAll('[data-feature-notification-marker]').forEach(el => {
                            el.remove();
                        });
                    })
            }
        },

        maybeCloseMenu(event) {
            if(
                !(
                    event.target.classList.contains('js-dont-close-menu') ||
                    event.target.closest('.js-dont-close-menu')
                )
            ) {
                this.showProfileMenu = false;
                this.showSettingsMenu = false;
                this.showNotifications = false;
            }
        }
    },
    mounted() {
        tippy('[data-tippy-content]');
    },
});
