window.addEventListener('load', () => {
    document.querySelectorAll('.are-you-sure').forEach(function(element) {
        element.addEventListener('click', function(e) {
            e.preventDefault();


            const title = element.dataset.areYouSureTitle ? element.dataset.areYouSureTitle : window.i18n['Er du sikker?'];
            const confirm = element.dataset.areYouSureConfirm ? element.dataset.areYouSureConfirm : window.i18n['Ja!'];
            const cancel = element.dataset.areYouSureCancel ? element.dataset.areYouSureCancel : window.i18n['Nej'];

            swal.fire({
                title: title,
                text: window.i18n["Du kan ikke fortryde denne handling"],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: confirm,
                cancelButtonText: cancel
            }).then((result) => {
                if (result.value == true) {
                    window.location = element.href;
                }
            })
        })
    })
});
